<template>
  <b-card title="Create Delivery Order">
    <b-modal v-model="disableButton" centered no-close-on-backdrop hide-footer hide-header>
      <div class="d-flex flex-column align-items-center justify-content-center py-3">
        <b-spinner medium variant="primary" label="Spinning"></b-spinner>
        <p class="mt-3">Processing Delivery Order</p>
      </div>
    </b-modal>
    <b-col cols="12">

      <b-row>
        <b-col cols="12">
          <b-form @submit.prevent="">
            <!--temporary hardcode only show this form select when current dealer not found-->
            <b-form-group>
              <span>Dealer <span style="color:red">(*)</span></span>
              <v-select
                v-if="!getDealer"
                v-model="dealerChoice"
                placeholder="----- Select Dealer -----"
                :options="dealer"
                :disabled="true"
                label="dealerName"
                :clearable="false"
                style="margin-top:5px !important;"
                class="mb-1"
              />
            </b-form-group>
            <b-row
              v-if="getDealer && user.roleRef.name === 'Super Admin'"
              style="margin-bottom:10px !important; margin-left:5px !important;"
            >
              <b-col cols="6">
                <b-row> Dealer Name : {{ dealerChoice.dealerName }}</b-row>
                <b-row> Industry Type : {{ dealerChoice.industryType }}</b-row>
              </b-col>
              <b-col cols="2">
                <b-button variant="danger" size="sm" @click="emptyDealer"
                  >X</b-button
                >
              </b-col>
            </b-row>

            <b-form-group>
              <span>Fulfilment Type <span style="color:red">(*)</span></span>
              <b-form-select
                v-model="form.orderType"
                style="margin-top:5px !important;"
                :options="orderTypes"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group
              v-if="
                form.orderType === 'DELIVERY' && form.deliveryType === 'SOWH'
              "
            >
              <span>Address Type <span style="color:red">(*)</span></span>
              <b-form-select
                v-model="form.addressType"
                style="margin-top:5px !important;"
                :options="optionAddress"
                @input="autoFillDealer"
              >
              </b-form-select>
            </b-form-group>

            <b-form-group>
              <span
                v-if="
                  form.orderType === 'SELF_COLLECT_AMK' ||
                    form.orderType === 'SELF_COLLECT_SUMITOMO'
                "
                >Collection Date <span style="color:red">(*)</span></span
              >
              <span v-if="form.orderType === 'DELIVERY'"
                >Requested Delivery Date<span style="color:red">(*)</span></span
              ><br />
              <date-picker
                v-model="form.deliveryDate"
                style="margin-top:5px !important;"
                :disabled-date="filterDisableDate"
                type="date"
                :use12h="true"
                :show-second="false"
                @pick="datePicked"
              />
            </b-form-group>
            <b-form-group v-if="form.orderType === 'DELIVERY'">
              <span>Timeslot <span style="color:red">(*)</span></span
              ><br />
              <b-form-select
                style="width: 210px; margin-top:5px !important;"
                v-model="form.timeslot"
                :options="timeslotOptions"
              >
                <b-form-select-option :value="null" disabled
                  >-- Please select timeslot --</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <span
                >Order Date<span style="color:red">(*)</span></span
              ><br />
              <date-picker
                v-model="form.orderDate"
                style="margin-top:5px !important;"
                :disabled-date="filterDisableDate"
                type="date"
                :use12h="true"
                :show-second="false"
              />
            </b-form-group>
            <b-form-group>
              <span
                >DO Number
                <span style="color:red"
                  >(*)</span
                ></span
              >
              <b-form-input
                maxlength="10"
                v-model="form.sapDo"
                style="margin-top:5px !important;"
                type="text"
                placeholder="Enter DO Number"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <span
                >SO Number
                <span style="color:red"
                  >(*)</span
                ></span
              >
              <b-form-input
                maxlength="10"
                v-model="form.sapSo"
                style="margin-top:5px !important;"
                type="text"
                placeholder="Enter SO Number"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <span
                >Plant
                <span style="color:red"
                  >(*)</span
                ></span
              >
              <b-form-input
                maxlength="4"
                v-model="form.plant"
                style="margin-top:5px !important;"
                type="text"
                placeholder="Enter Plant"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <span
                >Shipping Point
                <span style="color:red"
                  >(*)</span
                ></span
              >
              <b-form-select
                v-model="form.shippingPoint"
                style="margin-top:5px !important;"
                :options="shippingPoint"
                placeholder="Select Shipping Point"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <span
                >Distribution Channel
                <span style="color:red"
                  >(*)</span
                ></span
              >
              <b-form-input
                maxlength="2"
                v-model="form.distributionChannel"
                style="margin-top:5px !important;"
                type="text"
                placeholder="Distribution Channel"
              ></b-form-input>
            </b-form-group>
            <!-- <b-form-group>
              <span
                >DO Type
                <span style="color:red"
                  >(*)</span
                ></span
              >
              <b-form-input
                maxlength="4"
                v-model="form.doType"
                style="margin-top:5px !important;"
                type="text"
                placeholder="DO Type"
              ></b-form-input>
            </b-form-group> -->
            <!-- <b-form-group>
              <span
                >SO Type
                <span style="color:red"
                  >(*)</span
                ></span
              >
              <b-form-input
                maxlength="4"
                v-model="form.soType"
                style="margin-top:5px !important;"
                type="text"
                placeholder="SO Type"
              ></b-form-input>
            </b-form-group> -->
            <b-form-group>
              <span
                >SAP Created by
                </span
              >
              <b-form-input
                maxlength="12"
                v-model="form.sapCreatedBy"
                style="margin-top:5px !important;"
                type="text"
                placeholder="SAP Created by"
              ></b-form-input>
            </b-form-group>
            <!--section displays to order item-->
            <div class="cart__area">
              <div class="card__area-body">
                <div class="cart__area-columns mb-1">
                  <span>Item Model<span style="color:red">(*)</span> </span>
                  <!-- <span>
                    Line Item<span style="color:red">(*)</span>
                  </span> -->
                  <span>
                    Qty<span style="color:red">(*)</span>
                  </span>
                </div>

                <div
                  class="cart__area-columns"
                  :key="index"
                  v-for="(cartItem, index) in form.DO_details"
                >
                <v-select
                  class="style-chooser"
                  v-model="cartItem.material"
                  :index="index"
                  @search="ajaxSearch"
                  :options="searchItems"
                  :clearSearchOnSelect="true"
                >
                </v-select>

                  <!-- <b-form-input
                    type="number"
                    v-model="cartItem.lineitem"
                    placeholder="Enter Line Item"
                  ></b-form-input> -->

                  <b-form-input
                    type="number"
                    v-model="cartItem.qty"
                    placeholder="Enter Quantity"
                  ></b-form-input>

                  <b-button
                    variant="danger"
                    @click="cartRemoveItem(index)"
                  >
                    <b-icon-trash />
                  </b-button>
                </div>

                <!-- ======================================================= -->
                <div class="cart__area_mobile">
                  <div class="card__area-body_mobile">
                    <div class="cart__area-columns_mobile mb-1_mobile">
                      <b-row
                        :key="index"
                        v-for="(cartItem, index) in form.DO_details"
                      >
                        <b-col cols="12">
                          <b-row style="margin-buttom:10px">
                            <b-col cols="12">
                              <b-label
                                >Item Model
                                <span style="color:red">(*)</span></b-label
                              >
                              <v-select
                                :hint="
                                  `${cartItem.material} - ${cartItem.description}`
                                "
                                style="font-size:10px"
                                v-model="cartItem.material"
                                @search="ajaxSearch"
                                :options="searchItems"
                              >
                              </v-select>
                            </b-col>
                          </b-row>
                          <b-row style="margin-top:10px">
                            <b-col cols="5">
                              <b-form-input
                                v-model="cartItem.qty"
                                type="text"
                                placeholder="Qty"
                              ></b-form-input>
                            </b-col>
                            <!-- <b-col cols="5">
                              <b-form-input
                                v-model="cartItem.lineitem"
                                type="text"
                                placeholder="Line Item"
                              ></b-form-input>
                            </b-col> -->
                            <b-col cols="2">
                              <b-button
                                variant="danger"
                                @click="cartRemoveItem(index)"
                              >
                                <b-icon-trash />
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                    <b-button
                      @click="cartAddItem"
                      class="my-2"
                      variant="outline-success"
                    >
                      Add Item
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <!--END section displays to order item-->
            <div>
              <b-form-group v-if="form.deliveryType !== 'SOWH'|| form.addressType!='Default Address'">
                <span
                  >Name
                </span>
                <b-form-input
                  maxlength="40"
                  v-model="form.customer.nameFirst"
                  style="margin-top:5px !important;"
                  type="text"
                  placeholder="Enter Name"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <span
                  >Phone
                </span>
                <b-form-input
                  v-model="form.customer.phone"
                  style="margin-top:5px !important;"
                  type="text"
                  placeholder="Enter Phone"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <span
                  >Email
                </span>
                <b-form-input
                  v-model="form.customer.email"
                  style="margin-top:5px !important;"
                  type="email"
                  placeholder="Enter Email"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
              
                <span>
                  Postal
                </span>
                <br />
                <b-row>
                  <b-col md="9" cols="6">
                    <b-form-input
                      v-model="form.customer.postal"
                      type="text"
                      maxlength="6"
                      placeholder="Enter Postal"
                    ></b-form-input>
                  </b-col>
                  <b-col md="3" cols="6">
                    <b-button
                    @click="addAddressByPostal(form.customer.postal)"
                    variant="success"
                    class="mr-1"
                    :disable="disableButton"
                    >Auto Fill Address</b-button>
                  </b-col>
                </b-row>  
              </b-form-group>

              <b-form-group>
                <span
                  >Delivery Street Name
                </span
                >
                <b-form-input
                  v-model="form.customer.street"
                  style="margin-top:5px !important;"
                  type="text"
                  maxlength="27"
                  placeholder="Enter Delivery Street"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <span>City</span>
                <b-form-input
                  v-model="form.customer.city"
                  style="margin-top:5px !important;"
                  type="text"
                  placeholder="City"
                ></b-form-input>
              </b-form-group>
            </div>
            <b-form-group>
              <span>Customer PO</span>
              <b-form-input
                v-model="form.customerPo"
                style="margin-top:5px !important;"
                type="text"
                placeholder="Customer PO"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <span>Sales Person Code</span>
              <b-form-input
                maxlength="3"
                v-model="form.salesPersonCode"
                style="margin-top:5px !important;"
                type="text"
                placeholder="Sales Person Code"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <span>Salesman</span>
              <b-form-input
                v-model="form.salesman"
                style="margin-top:5px !important;"
                type="text"
                placeholder="Salesman"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <span>Project Name</span>
              <b-form-input
                v-model="form.projectName"
                style="margin-top:5px !important;"
                type="text"
                placeholder="Project Name"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <span>Pcode<span style="color:red">(*)</span></span>
              <b-form-input
                maxlength="4"
                v-model="form.pcode"
                style="margin-top:5px !important;"
                type="text"
                placeholder="Pcode"
              ></b-form-input>
            </b-form-group>

            <b-button
              v-if="permission.add"
              @click="addSaleBtn"
              type="submit"
              variant="success"
              class="mr-1"
              :disable="disableButton"
              >Add Delivery Order</b-button
            >
          </b-form>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import axios from "@/axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import lodash from "lodash";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { sleep, userAccess } from "@/utils/utils.js";
// import { isRaw } from "@vue/composition-api";

export default {
  components: {
    "date-picker": DatePicker,
    "v-select": vSelect,
  },
  data() {
    return {
      //form to save data
      form: {
        deliveryType: "SOSC", //default form will be self collect
        customer: {
          type: "Individual",
          companyName: "",
          nameFirst: "",
          nameLast: "",
          address: "",
          address2: "",
          address3: "",
          buildingName: "",
          phone: "",
          email: "",
          postal: "",
          city: "",
          //detail address
          street: "",
          block: "",
          floor: "",
          number: "",
          //detail address
        },
        DO_details: [{ material: "", lineitem: "", qty: "" }],
        deliveryDate: "",
        orderType: "SELF_COLLECT_AMK", //default form will be self collect
        orderDate: "",
        sapDo: "",
        sapSo: "",
        plant: "",
        shippingPoint: "",
        distributionChannel: "",
        doType: "",
        soType: "",
        sapCreatedBy: "",
        customerPo: "",
        salesPersonCode: "",
        salesman: "",
        projectName: "",
        pcode: "",
        timeslot: null,
        addressType: "",
        priority: "",
        deliveryCity: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryInstruction: "",
        dealer: "",
      },
      // mandatory for Address Type "Default Address"
      mandatoryStatus: true,

      // state for Delivery Block No and Delivery Unit No fields appear or not
      doBlockandUnitFieldsState: false,

      //end of form to save data
      stockError: {},
      item: null,
      itemQty: 0,
      // warehouses: [],
      promptDraft: false,
      shippingPoint : [
        { value: "1001", text: "Daikin Warehouse" },
        { value: "1003", text: "Sumitomo Warehouse" },
      ],
      orderTypes: [
        { value: "SELF_COLLECT_AMK", text: "Self Collect AMK" },
        { value: "SELF_COLLECT_SUMITOMO", text: "Self Collect Sumitomo" },
        { value: "DELIVERY", text: "Delivery" },
      ],
      deliveryTypeOptions: [
        { value: "SOEC", text: "End Customer" },
        { value: "SOWH", text: "Internal Warehouse Delivery" },
      ],
      addBy: "item",
      addItemByOptions: [
        { value: "item", text: "Item" },
        { value: "bundle", text: "Bundle" },
        { value: "category", text: "Category" },
      ],
      optionAddress: ["Default Address", "New Delivery Address"],
      categoriesOptions: [],
      category: null,
      categoryItems: [],
      categoryItem: null,
      timestate: null,
      holidays: [],
      today: 0,
      deliveryDate: "",
      showBundleModal: false,
      selectedBundle: null,
      bundleQuantity:1,
      publishedItems: [],
      timeslotOptions: [],
      searchItems: [],
      ajaxItem: null,
      arrayStreet: [],
      arrayBuildingName: [],
      dealerChoice: "For Manual DO Creation", //This is if dealer doesn't detect at active user

      //button disable
      disableButton: false,
    };
  },
  created() {
    document.title = "Manual Create | RSP";
    //get current dealer
    this.$store.dispatch("dealer/getCurrentDealer").then((x) => {
      var payload = {};
      if (this.getDealer) {
        //if user is dealer
        payload.industryType = this.getDealer.industryType;
      } else {
        payload = undefined;
      }
    });
  },
  mounted() {
    let now = new Date();
    this.today = Number.parseInt(String(now.getDate()).padStart(2, "0"));

    //temporary get api customer from axios
    this.$store.dispatch("dealer/getAllDealers");

    //temporary get current user
    this.$store.dispatch("auth/fetchUser");
  },
  methods: {
    ...mapActions({
      getAddressByPostal: "mapAPI/getAddressByPostal",
      getItems: "item/getItems",
    }),
    async addAddressByPostal(postal){
      if(postal.length!=6){
        this.$bvToast.toast(`Postal not valid`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      var address;
      try{
        address = await this.getAddressByPostal(postal);
      }catch(err){
        this.$bvToast.toast(`One Map API Returns Error`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      if(address.length<=0){
        this.$bvToast.toast("Postal "+postal+" has no address", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      var arrayStreet=[];
      var arrayBuildingName=[];
      var arrayBlockNumber=[];
      for (let postalData of address) {
        let { ROAD_NAME,BLK_NO,BUILDING } = postalData;
        arrayStreet.push(ROAD_NAME);
        arrayBuildingName.push(BUILDING);
        arrayBlockNumber.push(BLK_NO);

      }
      let newSetArrayStreet = [...new Set(arrayStreet)];
      let newSetArrayBuildingName = [...new Set(arrayBuildingName)];
      let newSetArrayBlockNumber = [...new Set(arrayBlockNumber)];

      if (newSetArrayStreet.length > 1) {
        this.arrayStreet = newSetArrayStreet;
        this.isStreetArray = true;
      } else {
        this.form.customer.street = newSetArrayStreet[0];
        this.isStreetArray = false;
      }
      if (newSetArrayBuildingName.length > 1) {
        this.arrayBuildingName = newSetArrayBuildingName;
        this.isBuildingNameArray = true;
      } else {
        this.form.customer.buildingName = newSetArrayBuildingName[0];
        this.isBuildingNameArray = false;
      }
      if(newSetArrayBlockNumber.length==1){
        this.form.customer.block= newSetArrayBlockNumber[0];
      }

      this.$bvToast.toast(`Address found`, {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
    emptyDealer() {
      this.mandatoryStatus = true;
      this.form.deliveryType = "SOEC";
      this.dealerChoice = "";
      this.form.customer.address = "";
      this.form.customer.email = "";
      this.form.customer.postal = "";
      this.form.customer.phone = "";
      this.form.customer.nameFirst = "";
      this.form.customer.street = "";
      this.form.customer.block = "";
      this.form.customer.buildingName = "";
    },
    cleanUpDelivery() {
      if (
        this.form.orderType === "DELIVERY" &&
        this.form.deliveryType === "SOWH"
      ) {
        this.form.addressType = "Default Address";
        this.mandatoryStatus = false;
      } else {
        this.mandatoryStatus = true;
      }
      if (
        this.form.orderType === "DELIVERY" &&
        this.form.deliveryType === "SOEC"
      ) {
        this.form.customer = {
          type: "Individual",
          companyName: "",
          nameFirst: "",
          nameLast: "",
          address: "",
          address2: "",
          address3: "",
          buildingName: "",
          phone: "",
          email: "",
          postal: "",
          city: "Singapore",
          //detail address
          street: "",
          block: "",
          floor: "",
          number: "",
          //detail address
        };
      }
      if (
        this.form.deliveryType === "SOWH" &&
        this.form.addressType === "Default Address"
      ) {
        // this.form.customer.address = this.getDealer.billingAddress1.concat(" ", this.getDealer.billingAddress2);
        this.form.customer.email = this.getDealer.dealerEmailAddress;
        this.form.customer.postal = this.getDealer.postalCode;
        this.form.customer.phone = this.getDealer.phone;
        this.form.customer.nameFirst = this.getDealer.dealerName;
        this.form.customer.street = this.getDealer.billingAddress1;
        this.form.customer.buildingName = this.getDealer.billingAddress2;
      } else {
        this.form.customer = {
          type: "Individual",
          companyName: "",
          nameFirst: "",
          nameLast: "",
          address: "",
          address2: "",
          address3: "",
          buildingName: "",
          phone: "",
          email: "",
          postal: "",
          city: "Singapore",
          //detail address
          street: "",
          block: "",
          floor: "",
          number: "",
          //detail address
        };
      }
    },
    autoFillDealer() {
      //ini buat load data waktu pilih warehouse dengan informasi detailnya
      if (
        this.form.deliveryType === "SOWH" &&
        this.form.addressType === "Default Address"
      ) {
        this.mandatoryStatus = false;
        this.form.customer.address = this.getDealer.billingAddress1.concat(
          " ",
          this.getDealer.billingAddress2
        );
        this.form.customer.email = this.getDealer.dealerEmailAddress;
        this.form.customer.postal = this.getDealer.postalCode;
        this.form.customer.phone = this.getDealer.phone;
        this.form.customer.nameFirst = this.getDealer.dealerName;
        this.form.customer.street = this.getDealer.billingAddress1;
        this.form.customer.buildingName = this.getDealer.billingAddress2;
      } else if (
        this.form.deliveryType === "SOWH" &&
        this.form.addressType === "New Delivery Address"
      ) {
        this.form.customer.address = "";
        this.form.customer.email = "";
        this.form.customer.postal = "";
        this.form.customer.phone = "";
        this.form.customer.nameFirst = "";
        this.form.customer.street = "";
        this.form.customer.block = "";
        this.form.customer.buildingName = "";
        this.mandatoryStatus = true;
        
      } else {
        this.form.customer.address = this.form.customer.address
          ? this.form.customer.address
          : "";
        this.form.customer.email = this.form.customer.email
          ? this.form.customer.email
          : "";
        this.form.customer.postal = this.form.customer.postal
          ? this.form.customer.postal
          : "";
        this.form.customer.phone = this.form.customer.phone
          ? this.form.customer.phone
          : "";
        this.form.customer.nameFirst = this.form.customer.nameFirst
          ? this.form.customer.nameFirst
          : "";
        this.form.customer.nameLast = this.form.customer.nameLast
          ? this.form.customer.nameLast
          : "";
        // this.mandatoryStatus = false;
      }
      // console.log(this.form.customer)
    },
    addSaleBtn() {
      //ini waktu klik confirm purchase dimana akan kirim PO ke SAP. dengan sebelumnya akan check stock dulu per item
      //for handling dealer info
      this.disableButton = true;
      let dealer = this.$store.getters["dealer/getDealers"].filter(
        (x) => x.dealerName == "For Manual DO Creation"
      )[0];
      console.log('dealer', dealer);
      this.form.dealer = dealer
      
      if(this.form.deliveryDate == "" || this.form.deliveryDate == null || this.form.deliveryDate == undefined){
        this.$bvToast.toast(`Please Add Delivery Date/Collection Date`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      if(this.form.orderDate == "" || this.form.orderDate == null || this.form.orderDate == undefined){
        this.$bvToast.toast(`Please Add Order Date`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      if(this.form.sapDo == "" || this.form.sapDo == null || this.form.sapDo == undefined){
        this.$bvToast.toast(`Please Add DO Number`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      if(this.form.sapSo == "" || this.form.sapSo == null || this.form.sapSo == undefined){
        this.$bvToast.toast(`Please Add SO Number`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      if(this.form.plant == "" || this.form.plant == null || this.form.plant == undefined){
        this.$bvToast.toast(`Please Add Plant`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      if(this.form.shippingPoint == "" || this.form.shippingPoint == null || this.form.shippingPoint == undefined){
        this.$bvToast.toast(`Please Add Shipping Point`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      if(this.form.distributionChannel == "" || this.form.distributionChannel == null || this.form.distributionChannel == undefined){
        this.$bvToast.toast(`Please Add Distribution Channel`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        }); 
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      for (const el of this.form.DO_details) {
        if(!el.material ) {
          this.$bvToast.toast(`Please select item model`, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          this.disableButton = false;
          return;
        }
        if(!el.qty ) {
          this.$bvToast.toast(`Item quantity cannot empty`, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          this.disableButton = false;
          return;
        }
        // if(!el.lineitem ) {
        //   this.$bvToast.toast(`line item cannot empty`, {
        //     title: "Failed",
        //     variant: "danger",
        //     solid: true,
        //   });
        //   this.disableButton = false;
        //   return;
        // }  
      }
      if(this.form.pcode == "" || this.form.pcode == null || this.form.pcode == undefined){
        this.$bvToast.toast(`Please Add Pcode`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.disableButton = false;
        // Prevent Any Saving Data From Run
        return;
      }
      // if(this.form.customer.nameFirst == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
      //   this.$bvToast.toast(`Please Add Name`, {
      //     title: "Failed",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.disableButton = false;
      //   // Prevent Any Saving Data From Run
      //   return;
      // }

      // if(this.form.customer.phone == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
      //   this.$bvToast.toast(`Please Add Phone Number`, {
      //     title: "Failed",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.disableButton = false;
      //   // Prevent Any Saving Data From Run
      //   return;
      // }

      // if(this.form.customer.email == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
      //     console.log("disable");
      //   this.$bvToast.toast(`Please Add Email`, {
      //     title: "Failed",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.disableButton = false;
      //   // Prevent Any Saving Data From Run
      //   return;
      // }

      // if(this.form.customer.postal == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
      //     console.log("disable");
      //   this.$bvToast.toast(`Please Add Postal`, {
      //     title: "Failed",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.disableButton = false;
      //   // Prevent Any Saving Data From Run
      //   return;
      // }

      // if(this.form.customer.street == "" && this.form.orderType === 'DELIVERY' && this.form.deliveryType === 'SOEC'){
      //     console.log("disable");
      //   this.$bvToast.toast(`Please Add Delivery Street Name`, {
      //     title: "Failed",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   this.disableButton = false;
      //   // Prevent Any Saving Data From Run
      //   return;
      // }
    
      this.form.customer.address = this.form.customer.block.concat(
        " ",
        this.form.customer.street
      );
      this.form.customer.address2 = (this.form.customer.floor=="")?"":"#"+this.form.customer.floor.concat(
        "-",
        this.form.customer.number,
        " ",
        this.form.customer.buildingName
      );
      if (this.form.timeslot === "" || this.form.timeslot === null) {
        this.form.customer.address3 = this.form.deliveryInstruction;
      } else {
        let timeSlotParsed=this.form.timeslot.split(":")
        this.form.customer.address3 = timeSlotParsed[1].concat(
          ", ",
          this.form.deliveryInstruction
        );
      }
      //end of concatenation

      //delivery detail value
      if (
        this.form.deliveryType === "SOWH" &&
        this.form.addressType === "Default Address"
      ) {
        this.form.deliveryCity = "";
        this.form.deliveryPostal = "";
        this.form.deliveryPhone = "";
        this.form.deliveryEmail = "";
        this.form.deliveryAddress = "";
        this.form.deliveryAddress2 = "";
        this.form.deliveryAddress3 = "";
        this.form.customer.address = "";
        this.form.customer.email = "";
        this.form.customer.postal = "";
        this.form.customer.phone = "";
        this.form.customer.nameFirst = "";
        this.form.customer.street = "";
        this.form.customer.block = "";
        this.form.customer.floor ="";
        this.form.customer.buildingName = "";
      } else {
        this.form.CustomerName1= this.form.customer.nameFirst
        this.form.deliveryCity = this.form.customer.city;
        this.form.deliveryPostalCode = this.form.customer.postal;
        this.form.phone = this.form.customer.phone;
        this.form.email = this.form.customer.email;
        this.form.deliveryAddress1 = this.form.customer.address;
        this.form.deliveryAddress2 = this.form.customer.address2;
        this.form.deliveryAddress3 = this.form.customer.address3;
      }
      //end of delivery detail value

      //condition of priority by timeslot
      if (this.form.timeslot === "Morning: 9AM - 12PM") {
        this.form.priority = "11";
      } else if (this.form.timeslot === "Afternoon: 1PM - 3PM") {
        this.form.priority = "12";
      } else if (this.form.timeslot === "Afternoon: 3PM - 6PM") {
        this.form.priority = "13";
      } else if (this.form.orderType === "SELF_COLLECT_AMK") {
        this.form.priority = "03";
      } else if (this.form.orderType === "SELF_COLLECT_SUMITOMO") {
        this.form.priority = "05";
      }

      this.form.dealerNo = this.form.dealer.dealerCode
      this.form.DO_details = this.form.DO_details.map((item, index) => {
        console.log('index', typeof(index));
        return {
          qty: item.qty,
          lineitem: index + 1,
          material: item.material.itemModel,
        };
      });

      console.log('this.form.DO_details', this.form.DO_details);
      const orderDate = new Date(this.form.orderDate);
      // Mengonversi tanggal menjadi format yang diinginkan (misalnya: "DDMMYYYY")
      const year = orderDate.getFullYear();
      const month = String(orderDate.getMonth() + 1).padStart(2, "0"); // Menambahkan "0" di depan jika perlu
      const day = String(orderDate.getDate()).padStart(2, "0"); // Menambahkan "0" di depan jika perlu

      // Format yang diharapkan untuk `orderDate` dalam API (misalnya: YYYY-MM-DD)
      const formattedOrderDate = `${day}${month}${year}`;
      this.form.orderDate = formattedOrderDate

      const deliveryDate = new Date(this.form.deliveryDate);
      // Mengonversi tanggal menjadi format yang diinginkan (misalnya: "DDMMYYYY")
      const yearD = deliveryDate.getFullYear();
      const monthD = String(deliveryDate.getMonth() + 1).padStart(2, "0"); // Menambahkan "0" di depan jika perlu
      const dayD = String(deliveryDate.getDate()).padStart(2, "0"); // Menambahkan "0" di depan jika perlu

      // Format yang diharapkan untuk `orderDate` dalam API (misalnya: YYYY-MM-DD)
      const formattedDeliveryDate = `${dayD}${monthD}${yearD}`;

      this.form.orderDate = formattedOrderDate
      this.form.deliveryDate = formattedDeliveryDate

      axios.post('sales-and-purchase/drcc/create-manual/do', this.form).then((response) => {
        this.disableButton = false;
        this.$bvToast.toast("Create Delivery Order Successfully", {
        title: "Success",
        variant: "success",
        solid: true,
        })
      })
      .catch((e) => {
        this.disableButton = false;
        this.$bvToast.toast(e.response.data.errors? e.response.data.errors[0].msg : e.response.data.message, {
        title: "Failed",
        variant: "danger",
        solid: true,
        })
      })     
    },
    filterDisableDate(date, arrayDate) {
      let day = date.getDay();
      let now = new Date();

      // Disable All Sunday Days
      // No Delivery On Sunday
      if (day === 0) {
        return true;
      }

      // Disable Delivery Today
      let checkedDay = Number.parseInt(String(date.getDate()).padStart(2, "0"));
      if (checkedDay == this.today) {
        return true;
      }

      // Disable Holiday Day
      let month = date.getMonth();
      let checkElem = this.holidays.filter((h) => {
        if (h.month == month && h.day == checkedDay) {
          return true;
        }

        return false;
      });
      if (checkElem.length > 0) {
        return true;
      }
      // End Disable Holiday Day

      return false;
    },
    cartRemoveItem(index) {
      //ini buat hapus 1 baris item list detail
      this.form.DO_details.splice(index, 1);
    },
    cartAddItem() {
      //ini buat tambah 1 baris item list detail
      this.form.DO_details.push({});
    },
    datePicked(date) {
      //ini logic buat datepicker
      // Rule 1: Delivery Or Collect Date For Today Is Disable
      // Rule 2: Delivery Can Only Happen Next Day Or The Day After
      // Rule 3: Time Slot Option Is Tied To Time When Order Created
      // Rule 4: Time Order Created After Afternoon (18:00-00:00) Timeslot Available Is Next Day Morning
      // Rule 5: Time Order Created At Morning (00:00 - 12:00) Timeslot Available Is Afternoon Slot Next Day
      // Rule 6: Saturday (Half Day ?) And Sunday Is Off Day (No Delivery Happen Here)
      // Rule 7: If Today Is Friday And Choose Delivery Date At Saturday Only Morning Slot Available
      let selectedDate = moment(date);
      let selectedDay = selectedDate.days();

      let now = moment();
      let currentHour = now.format("HH");
      let currentDay = now.days();
      let currentDate = now.date();

      let predictDate = moment(now).add(1, "days");
      let isNextDay = predictDate.date() === selectedDate.date() ? true : false;

      if (selectedDay === 6) {
        this.timeslotOptions = ["Morning: 9AM - 12PM"];

        return;
      }

      if (!isNextDay) {
        this.timeslotOptions = [
          "Morning: 9AM - 12PM",
          "Afternoon: 1PM - 3PM",
          "Afternoon: 3PM - 6PM",
        ];
        return;
      }

      // Order Created At Night (Only Morning Slot Should Available) Next Day
      if (currentHour >= 18 && currentHour <= 23) {
        this.timeslotOptions = ["Morning: 9AM - 12PM"];

        return;
      }

      // Order Created At Morning (Including The Night) Afternoon Slot Available Next Day
      if (currentHour <= 12) {
        this.timeslotOptions = ["Afternoon: 1PM - 3PM", "Afternoon: 3PM - 6PM"];

        return;
      }

      // Order Created After Morning
      if (currentHour > 12) {
        this.timeslotOptions = ["Morning: 9AM - 12PM"];

        return;
      }
    },
    ajaxSearch: lodash.debounce(function(search, loading,index) {
      if(search !== ""){
        loading(true);
        var currentDealer;
        if (this.getDealer && this.getDealer != "") {
          //if user is dealer
          currentDealer = this.getDealer;
        } else {
          //if user is not dealer
          currentDealer = this.dealerChoice;
        }
          console.log('masuk');
          this.getItems({
            description: search,
          })
            .then((data) => {
              this.searchItems = data.data.map((item) => {
                if (item.status === "") {
                  return {
                    label: `${item.itemModel} - ${item.description}`,
                    description: item.description,
                    itemModel: item.itemModel,
                    ...item,
                  };
                }
              });
            
              loading(false);
            })
            .catch((e) => {
              console.log(e);
            });
          }
          loading(false);
      }, 500),
    ajaxItemSelected(item) {
      this.ajaxItem = item;
    },
  },
  computed: {
    permission() {
      let result = userAccess("Manual Create Delivery", "manualcreate_menu")
      return result
    },
    user() {
      //buat cek informasi akun yang sedang login
      return this.$store.getters["auth/getActiveUser"];
    },
    dealer() {
      return this.$store.getters["dealer/getDealers"].filter(
        (x) => x.status === ""
      );
    },
    getDealer() {
      //ini buat cek apakah akun yang sedang login termasuk dealer atau bukan
      //kalau user bukan dealer, return undefined
      var tempDealer = this.$store.getters["dealer/getCurrentDealers"];

      if (tempDealer === null) {
        tempDealer = this.dealerChoice;
      }
      return tempDealer;
    },
  },
};
</script>

<style>
.deliveryUnitNo {
  width: 100px;
}
.cart__area {
  display: flex;
  flex-direction: column;
}

.cart__area-body {
  display: grid;
  grid-template-rows: 100%;
  overflow-x: scroll;
  margin: 10px 0;
}

.cart__area-columns {
  display: grid;
  grid-template-columns: 40% 15% 15% 15% auto;
  column-gap: 15px;
  margin: 0 0 5px 0;
}

.cart__area-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.cart__area-line-info {
  display: flex;
  align-items: center;
  margin: 0 0 5px 0;
}

.cart__area-line-info span {
  width: 100%;
  margin: 0 5px 0 0;
}

.cart__area-line-info input {
  width: 100px;
}

.cart-area-columns span {
  font-weight: bold;
}

.v-select .vs__dropdown-toggle {
  height: 2.714rem;
  padding: 0;
}

@media only screen and (max-width: 681px) {
  /* .halo {
    background-color: rebeccapurple;
    
  } */
  .cart__area-columns {
    display: none;
  }
}

@media only screen and (min-width: 682px) {
  /* .halo {
    background-color: red;
  } */
  .halo {
    background-color: red;
  }

  .cart__area-columns_mobile {
    display: none;
  }
}
</style>
